import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import ContextConsumer from "../../../layouts/Context"
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import {RiArrowDropRightLine, RiArrowDropLeftLine} from 'react-icons/ri'
import parse from 'html-react-parser'

const _Infobox = styled.div`
width:100vw;
height:100vh;
position: fixed;
z-index:99999999;
background:rgba(0,0,0,0.8);
top:0;
left:0;
display: flex;
justify-content: center;
align-items: center;
opacity: ${props => props.isActive.opacity};
pointer-events: ${props => props.isActive.pointerEvents};
${({theme}) => theme.easing('0.5')}  

    .close{
    position: absolute;
    top:2rem;
    right:2rem;
    color:white;
    pointer-events: none;
    }

    .lightbox-inner{
    // min-width: 90%;
    // min-height:80%;
    width: 90%;
    max-width:600px;    
    background:white;
    padding:2rem;
    position: relative;    
    text-align:center;
    transform:${props => props.isActive.innerScale};
    ${({theme}) => theme.easing('0.25')}  

        .gatsby-image-wrapper{
        margin-bottom: 2rem;;
        }

        p{
        line-height:2.5rem !important;
        margin-bottom:1rem;

            &:last-of-type{
            margin-bottom:0;
            }

        }
        
    }


`

export default function Infobox() {
    return (
        <ContextConsumer>
            {({ data, set }) => 
                <_Infobox 
                isActive={data.exDisplayMenuOpen === true ? {opacity:1, pointerEvents:'all', innerScale:'scale(1)'} : {opacity:0, pointerEvents:'none', innerScale:'scale(0.5)'}} 
                className="lightbox-wrap"
                onClick={(e) => {
                        if(e.target.classList.contains('lightbox-wrap')){
                            set({ 
                                image:data.image,
                                exDisplayMenuOpen: !data.exDisplayMenuOpen,
                                exDisplayMoreInfo: {},
                            })
                        }
                    }
                }
                >
                      <div className="close" >CLOSE</div>
                    <div className="lightbox-inner">
                        <div className="the-image">
                            <GatsbyImage image={getImage(data.image)} className="image" alt={'test'} />
                        </div>
                        {
                        data.exDisplayMoreInfo.moreInfo ? 
                            <div className="the-info">{parse(data.exDisplayMoreInfo.moreInfo )}</div>
                        : ""
                        }
                        <h2>{data.moreInfoTitle ? data.moreInfoTitle : ""}</h2>
                        {data.moreInfoImage ? data.moreInfoImage : ""}
                        {data.moreInfoManufacturer ? data.moreInfoManufacturer : ""}
                        {data.moreInfoPrice ? data.moreInfoPrice : ""}
                        {data.moreInfoSalePrice ? data.moreInfoSalePrice : ""}
                        {/* {data.moreInfoDimension.width ? data.moreInfoDimension.width : ""}
                        {data.moreInfoDimension.height ? data.moreInfoDimension.height : ""}
                        {data.moreInfoDimension.depth ? data.moreInfoDimension.depth : ""}
                        {data.moreInfoDimension.length ? data.moreInfoDimension.length : ""} */}
                        
                    </div>
                </_Infobox>
             }
        </ContextConsumer>
    )
}