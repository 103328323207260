import React, {useEffect, useRef} from 'react'
import Logo from "../../../images/logo-morland.inline.svg"
import { Link } from 'gatsby'
import { Styling } from './styled.js'
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory } from '@reach/router'

export default function Header({className, path}) {
  const mobNavRef = useRef()
  const header = useRef()
  
  function getDocHeight() {
    var D = document;
    return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
    )
  }


  function amountscrolled(){
    if(typeof window  !== 'undefined'){
      var winheight= window.innerHeight || (document.documentElement || document.body).clientHeight
      var docheight = getDocHeight()
      var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
      var trackLength = docheight - winheight
      var pctScrolled = Math.floor(scrollTop/trackLength * 100) // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
      

      if(pctScrolled > 0.005){
        header.current.classList.add('applyBackground')
      }else{
        header.current.classList.remove('applyBackground')
      }
    }
  }
 
  
  useEffect(() => {
    if(typeof window  !== 'undefined'){
      var docheight = getDocHeight()
      const caseStudiesRegex = new RegExp('\/ex-display\/[A-Za-z-]+')

      if(!window.location.pathname.match(caseStudiesRegex)){
        window.addEventListener("scroll", amountscrolled, false)
      }else{
        header.current.classList.add('applyBackground')
      }

      return globalHistory.listen(({ action }) => {
        if (action === 'PUSH'){      
          if(window.location.pathname.match(caseStudiesRegex)){
            window.removeEventListener("scroll", amountscrolled, false)
            header.current.classList.add('applyBackground')
          }else{
            window.addEventListener("scroll", amountscrolled, false)
            header.current.classList.remove('applyBackground')
          }
        }
      })

    }
  }, [])

  const data = useStaticQuery(graphql`
    query HeaderQuery {
        wpMenu(name: {eq: "Main Menu"}) {
            menuItems {
              nodes {
                path
                label
              }
            }
          }
    }
  `)

  const toggleMobNav = (e) => {
    const target =  document.querySelector('.navToggle');
    const header =  document.querySelector('.header-inner');
    const logo =  document.querySelector('.header-inner .logo');
    
    if(!target.classList.contains('active')){
      target.classList.add('active')
      logo.classList.add('active')
      mobNavRef.current.classList.add('active')
      if(header.classList.contains('applyBackground')){
        header.classList.add('hideBackground')
      }
    }else{
      target.classList.remove('active')
      logo.classList.remove('active')
      mobNavRef.current.classList.remove('active')
      if(header.classList.contains('hideBackground')){
        header.classList.remove('hideBackground')
      }
    }
  }

  const navItems = data.wpMenu.menuItems.nodes;
  return (
    <Styling>
      <div ref={header} className={`header-inner ${path === '/products/' ? 'dark' : 'transparent'} ${className}`}>
          <Link to='/'>
              <Logo className="logo"/>
          </Link>
        
          <nav className="nav">
              <ul>
                  {
                      navItems.map((item, index) => {
                          return(
                              <li key={item.path+'_'+index}>
                                  <Link  to={item.path}>{item.label}</Link>
                              </li>
                          )
                      })
                  }
              </ul>
          </nav>

          <button className={`navToggle ${path === '/products/' ? 'dark' : 'transparent'}`} onClick={(e) => toggleMobNav(e)}>
            <div className="line top"></div>
            <div className="line middle"></div>
            <div className="line bottom"></div>
          </button>
      </div>

        
      <nav className="mobNav" ref={mobNavRef}>
              <ul>
                  {
                      navItems.map((item, index) => {
                          return(
                              <li key={item.path+'_'+index}>
                                  <Link onClick={(e) => toggleMobNav(e)} to={item.path}>{item.label}</Link>
                              </li>
                          )
                      })
                  }
              </ul>
          </nav>
    </Styling>
  )
}
