import gsap from 'gsap'
import handleHideForm from './handleHideForm'

const toggleOffPageOut = (whatPage) => {
  if(whatPage.page === 'brand'){
    gsap.to('.exdisplayOffscreenWrapper', {opacity:0, pointerEvents:'none', duration:0})
    gsap.to('.brandsOffscreenWrapper', {opacity:1, pointerEvents:'all', duration:0})
  }else if(whatPage.page === 'product'){
    gsap.to('.brandsOffscreenWrapper', {opacity:1, pointerEvents:'all', duration:0})
  }else{
    gsap.to('.exdisplayOffscreenWrapper', {opacity:1, pointerEvents:'all', duration:0})
    gsap.to('.brandsOffscreenWrapper', {opacity:0, pointerEvents:'none', duration:0})
  }
  

  // if(whatPage.page === 'product'){
  //   gsap.to('.brandsOffscreenWrapper', {opacity:1, pointerEvents:'all', duration:0})
  // }else{
  //   gsap.to('.brandsOffscreenWrapper', {opacity:0, pointerEvents:'none', duration:0})
  // }
  if(typeof window !== 'undefined'){
    const translate = document.querySelectorAll('.offpage-translate, .transform-offscreen-child .curtains-canvas')

    if(window.innerWidth < 1024){
      gsap.to(translate, {x:-window.innerWidth, duration:1, ease:"expo.out"})
    }else{
      gsap.to(translate, {x:-window.innerWidth / 2, duration:1, ease:"expo.out"})
    }
  }
}

export default toggleOffPageOut;