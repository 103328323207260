import React from 'react'
import styled from 'styled-components'

const Styling = styled.header`
width: 100%;

    .header-inner{
    padding:2rem;
    display: flex;
    justify-content: space-between;
    position:fixed;
    align-items: center;
    width: 100vw;
    top:0;
    left:0;
    z-index: 100000;

        @media only screen and (min-width: 450px) {
        padding:2rem 4rem;
        }

       

        &:after{
        width:100%;
        height:100%;
        content:"";
        position:absolute;
        top:0;
        left:0;
        background:var(--blue_light);
        z-index: -1;
        ${({theme}) => theme.easing('0.5')} 
        transform: translateY(-110%);
        box-shadow: 2px -1px 22px -3px rgb(0 0 0 / 20%);
        }

        .logo{
        width: 16rem;

            @media only screen and (min-width: 1024px) {
                width: 20rem;
            }

            path{
            fill:var(--blue_light);
            ${({theme}) => theme.easing('0.5')} 
            }
            &.active{
                path{
                fill:var(--blue_light) !important;
                }
            }
        }

        .nav{
        display: none;

            @media only screen and (min-width: 1024px) {
            display: block;
            }
        
            ul{
            display: inline-flex;

                li{
                margin-left: 2rem;

                    a{
                    color:var(--blue_light);
                    text-decoration: none;
                    text-transform:lowercase;
                    ${({theme}) => theme.fontSize('15')}  
                    ${({theme}) => theme.easing('0.5')}  
                    border-bottom:1px solid transparent;

                        &[aria-current="page"],
                        &:hover{
                            border-bottom:1px solid var(--blue_light);
                        }

                    }

                }
            }
        }

        .navToggle{
        display: block;
        width:3rem;
        height:2rem;
        position: relative;
        cursor: pointer;
        border:0;
        background:none;
        -webkit-appearance: none;
        padding:0;
        ${({theme}) => theme.easing('0.5')}  
        z-index: 1000;

            @media only screen and (min-width: 1024px) {
            display: none;
            }

          

            .line{
            width: 3rem;
            height:0.2rem;
            position: absolute;
            background:var(--blue_dark);
            ${({theme}) => theme.easing('0.5')}  

                &.top{
                top:0;
                transform-origin: top left;
                }
                &.middle{
                top:50%;
                }
                &.bottom{
                top:100%;
                transform-origin: bottom left;
                }    
            }

            &.transparent{
                .line{
                background:var(--blue_light);
                }
            }

            &.active{

                .line{
                background:var(--blue_light);
                }
                .top{
                transform:rotate(45deg)
                }
                .middle{
                opacity: 0;
                }
                .bottom{
                transform:rotate(-45deg)
                }
            }

        }

        &.dark{

            .logo path{
            fill:var(--blue_dark);
            }
    
            .nav ul li a{
            color:var(--blue_dark);
                
                &[aria-current="page"],
                &:hover{
                border-bottom:1px solid var(--blue_dark);
                }
            }
        }

       &.applyBackground{

            &:after{
            transform:translateY(0);
            }

            .navToggle{
                .line{
                background:var(--blue_dark);
                }
            }

            .logo path{
            fill:var(--blue_dark);
            }
            
            .nav ul li a{
            color:var(--blue_dark);
                
                &[aria-current="page"],
                &:hover{
                border-bottom:1px solid var(--blue_dark);
                }
            }

            &.hideBackground{
                &:after{
                transform:translateY(-100%);
                }
                .navToggle{
                    .line{
                    background:var(--blue_light);
                    }
                }

                .logo path{
                fill:var(--blue_light);
                }
                
                .nav ul li a{
                color:var(--blue_light);
                    
                    &[aria-current="page"],
                    &:hover{
                    border-bottom:1px solid var(--blue_light);
                    }
                }
            }
        }
    }

    .mobNav{
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height:100%;
    background:var(--blue_dark);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    pointer-events: none;
    opacity:0;
    ${({theme}) => theme.easing('0.5')}  

        &.active{
        opacity: 1;
        pointer-events: all;
        }

        li{
        margin-bottom: 1rem;
        width: 100%;
        position: relative;
        display: block;

            &:last-child{
            margin-bottom: 0;
            }

            a{
            font-size:5rem;
            color:var(--blue_light);
            text-decoration:none;
            width: 100%;
            display: block;
            }
        }

    }

    .mobNav{
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height:100%;
    background:var(--blue_dark);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    pointer-events: none;
    opacity:0;
    ${({theme}) => theme.easing('0.5')}  

        &.active{
        opacity: 1;
        pointer-events: all;
        }

        li{
        margin-bottom: 1rem;
        width: 100%;
        position: relative;
        display: block;

            &:last-child{
            margin-bottom: 0;
            }

            a{
            font-size:clamp(2.5rem, 4vw, 4rem);
            color:var(--blue_light);
            text-decoration:none;
            width: 100%;
            display: block;
            }
        }

    }

`

export { Styling }