import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
width: 50%;
text-align:left;
margin-top:4rem;

    @media only screen and (min-width: 760px) {
    text-align:right;
    margin-top:0;
    }

    .text{
    margin-bottom: 1rem;
    }

    .name{
    text-transform:capitalize;
    margin-bottom: 1rem;
    }

`

export { Styling }