import React from 'react'
import styled from 'styled-components'

const Styling = styled.footer`
    .footer{
    background:var(--blue_dark);
    padding:4rem 2rem;
    z-index: 100;
    color:var(--blue_light);
    ${({theme}) => theme.fontSize('16')}  
    ${({theme}) => theme.fontSizeLineHeight('16', '1.5')}  

        @media only screen and (min-width: 450px) {
            padding:4rem;
        }
        @media only screen and (min-width: 1024px) {
            padding:8rem;
        }


        .upper{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 4rem;
        padding-bottom: 4rem;
        border-bottom:1px solid var(--blue_light);

            @media only screen and (min-width: 760px) {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom:0;
            flex-wrap: nowrap;
            }

            .logo{
            width:130px;
            height:100px;
            }

            .reviews_slider{
            width: 100%;

                @media only screen and (min-width: 760px) {
                width: 50%;
                }    

            }

        }

        .lower{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin-top: 6rem;
        }


        a{
        color:inherit;
        text-decoration:none;
        ${({theme}) => theme.easing('0.2')}  

            &:hover{
            opacity: 0.5;
            }

        }

        .col:first-child{
        grid-column: span 6;
        margin-bottom: 4rem;
        margin-bottom: 4rem;
        padding-bottom: 4rem;
        border-bottom:1px solid var(--blue_light);
        
            @media only screen and (min-width: 860px) {
            margin-bottom: 8rem;
            grid-column: span 3;
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom:0;
            }
        
            @media only screen and (min-width: 1024px) {
                grid-column: span 4;
            }

        }
   
        .col:last-child{
        grid-column-end: 7;
        border-bottom:0 !important;
        padding-bottom:0 !important;
        margin-bottom:0 !important;
        }

        .col{
        grid-column: span 6;
        margin-bottom: 4rem;
        padding-bottom: 4rem;
        border-bottom:1px solid var(--blue_light);
        
            @media only screen and (min-width: 500px) {
            grid-column: span 3;
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom:0;
            }

            @media only screen and (min-width: 860px) {
                grid-column: span 1;
            }

            @media only screen and (min-width: 1024px) {
                grid-column: span 1;
            }
        }

        .address,
        .phone{
            margin-bottom:2rem;
        }

        .address,
        .phone,
        .fax{
            ${({theme}) => theme.fontSize('20')}  
            ${({theme}) => theme.fontSizeLineHeight('20','1.5')}  
            margin-bottom:2rem;
        }

        .social{
        display:flex;
        margin-top:2rem;

            li{
            margin-right:2rem;
                a svg{
                font-size:2.6rem;
                }
            }

        }

        .col{
            ul{
                li{
                margin-bottom:1rem;

                    &:last-child{
                    margin-bottom: 0;
                    }

                    a{
                    ${({theme}) => theme.fontSize('17')}  
                    ${({theme}) => theme.fontSizeLineHeight('17','1.5')}  
                    }

                }
            }
        }
    }
`

export { Styling }