import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
width:100vw;
height: 100vh;
background: var(--blue_dark);
position: fixed;
right:0;
top:0;
color:var(--blue_light);
z-index: 0;

    @media only screen and (min-width: 1024px) {
        width:50vw;
    }

    .continueShopping{
    position:absolute;
    left:0;
    bottom:0;
    background:var(--blue_dark);
    width:100%;
    padding:2rem 4rem;
    z-index:20;

        .button{
            font-size:1.3rem;
        }

    }
    .wishlist-overview{
    padding:4rem 4rem 8rem 4rem;
    width: 100%;
    overflow: scroll;
    height: 100%;
    }

    .wishlist-footer{
    width: 100%;
    position: relative;
    padding-top:2rem;
    border-top:1px solid rgba(255,255,255,.2);
    display: flex;
    justify-content: space-between;
    }

    .wishlist-list{
    width: 100%;

        .wishlist-list-item{
        width: 100%;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        
            .info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width:100%;

                .info-inner{
                width:calc(100% - 80px - 2rem);
                }

                .title{
                margin-bottom: 1rem;
                }

                .price{
                
                }

                .image-outer{
                margin-right:1rem;
                border:0.5rem solid var(--white);
                background:var(--white);

                    .image{
                    width:80px;
                    height:80px;
                    }

                }
            }

            .remove{
            color:white;
            cursor: pointer;
            position: relative;
            z-index: 10;

                svg{
                position: absolute;
                right:0px;
                top:2px;
                z-index: 100;
                }
            
                .hidden{
                position: relative;
                width:110px;
                overflow: hidden;
                height:20px;
                display:none;

                    @media only screen and (min-width: 1024px) {
                    display: block;
                    }

                    .hidden-inner{
                        position: absolute;
                        top:5px;
                        right:20px;
                        text-align:right;
                        width:110px;
                        transform: translateX(100%);
                        ${({theme}) => theme.fontSize('10')}  
                        ${({theme}) => theme.easing('0.5')}  
                    }
                }

                &:hover{
                    .hidden .hidden-inner{
                        transform: translateX(0%);
                    }
                }

            }

        }
    }

    .form-wrapper{
    width:100vw;
    height:100%;
    position:fixed;
    top:0;
    right:0;
    /* display:flex;
    justify-content: center; */
    /* align-items: center; */
    padding:4rem;
    flex-wrap: wrap;
    background:var(--blue_dark);
    z-index: 10;
    transform:translateX(100%);
    ${({theme}) => theme.easing('0.5')}  

        @media only screen and (min-width: 1024px) {
            width:50vw;
        }


        .text{
        width: 100%;
        margin-bottom: 2rem;
        text-align:center;
        font-size:1.5rem;
        line-height:1.75em;
        }

        form{
        width: 100%;

            select,
            textarea,
            input{
            width: 100%;
            margin-bottom: 1rem;
            padding:1rem;
            }

            textarea{
            display: none;
            }
        }
        
    }
    
    .button{
    ${({theme}) => theme.button('--white')}  
    

        &:hover{
        background:var(--white);
        color:var(--blue_dark);
        }

    }

`

export { Styling }