const mixins = {
    opacity: (opacity) => `
        opacity: ${opacity};
    `,
    fontSize: (size) => `
        font-size: ${size}px; 
        font-size: ${(size / 10) * 1}rem;`
    ,
    fontSizeLineHeight: (size, lineHeight) => `
        line-height:${(size / 10) * lineHeight}rem;`
    ,
    minerva: () => `
        font-family: minerva-modern, sans-serif;`
    ,
    baskerville: () => `
        font-family: 'Libre Baskerville';`
    ,
    easing: (time)=> `
        transition:${time}s all ease-in-out;`
    ,
    button: (color) => `
        cursor:pointer; font-family: 'Libre Baskerville'; 
        font-style:italic; color:var(${color ? color : '--blue_dark'}); 
        padding:1rem; 
        background:transparent; 
        border:1px solid var(${color ? color : '--blue_dark'}); 
        text-decoration:none;`
    ,
    buttonHover: (color, bg) => `
        background:var(${bg ? bg : '--blue_dark'}); 
        color:var(${color ? color : '--blue_light'});`
    ,
    buttonSolid: (color, bg) => `
        cursor:pointer; 
        background:var(${bg ? bg : '--blue_dark'}); 
        border:1px solid var(${bg ? bg : '--blue_dark'}); 
        color:var(${color ? color : '--blue_light'});`
    ,
    textStyling: () => `
        .title_xl,
        .title_l,
        .title_m,
        .title_s,
        h2{
        font-family: 'Libre Baskerville';
        color:var(--blue_dark);
        margin-bottom: 4rem;
        }
        
        h2{
        font-size: clamp(2.5rem, 6vw, 4rem);
        line-height:1.25em;
        }

        ol{
        list-style:auto;
        padding-left:clamp(2rem, 5vw, 5rem);

            li::marker{
            font-family: 'Libre Baskerville';
            font-size: clamp(2.8rem, 5vw, 4rem);
            line-height:1.25em;
            }

            li{
                strong{
                // font-size: 30px; font-size: 3rem;  line-height:4.5rem;
                font-size: clamp(2rem, 5vw, 3rem);
                line-height:1.5em;
                }
            }

        }

        ol li,
        ul li,
        p:not([class]){
        margin-bottom: 2rem;  
        // font-size: 20px; font-size: 2rem; 
        // line-height:3.5rem;

        font-size: clamp(1.6rem, 1.8vw, 1.8rem);
        line-height:1.75em;
        }

        p:not([class]){
        width:100%;

            @media only screen and (min-width: 1024px) {
            width:80%;
            }

        }

        em{
        font-style:italic;
        }
    `
}

export {
    mixins
}