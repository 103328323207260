import gsap from 'gsap'

const toggleOffPageIn = (targetClass, ignoreOverride) => {

  const targets = gsap.utils.toArray([
    '.offpage-translate',
    '.transform-offscreen-child .curtains-canvas'
  ])

  // targets.forEach(target => {
  //   target.addEventListener('click', function(e){
  //     const closest = e.target.closest(targetClass);
  //     if(typeof window !== 'undefined'){
  //       if(closest === null){
  //         gsap.to(targets, {x:0, duration:1, ease:"expo.out"})
  //       }
  //     }
  //   })
  // })
  
  if(ignoreOverride !== true){
    gsap.to(targets, {x:0, duration:1, ease:"expo.out"})
  }
}
  

  export default toggleOffPageIn;