import React, {useRef, useState} from 'react';
import {FiStar} from 'react-icons/fi'
import toggleOffPageShow from '../../../utils/toggleOffPageShow'
import toggleOffPageHide from '../../../utils/toggleOffPageHide'
import handleHideForm from '../../../utils/handleHideForm';
import { Styling } from './styled.js'
import gsap from 'gsap';
export default function WishlistCount(
    {wishlist,
    wishlistDrawerActive,
    setWishlistDrawerActive
    }) {

  console.log('wishlist test', wishlistDrawerActive)
    const wishlistCount = useRef()
    const isActive = wishlistDrawerActive
  
    const handleShowWishlistDrawer = () => {
      console.log('clicked', wishlistDrawerActive)
        if(wishlistDrawerActive === false){
          toggleOffPageShow({page:'other'})
          gsap.to('#main', {pointerEvents:'none'})
          setWishlistDrawerActive(true)
        }else{
          gsap.to('#main', {pointerEvents:'all'})
          toggleOffPageHide('.exdisplay-product')
          handleHideForm()
          setWishlistDrawerActive(false)
        }
    } 

  return (
    <Styling>
      <button ref={wishlistCount} className={`wishlist-count ${wishlist !== null && wishlist.length > 0 ? 'active' : ''}`} onClick={handleShowWishlistDrawer}>
        <FiStar />
        <div className="count">{wishlist !== null ? wishlist.length : '0'}</div>
        <div className="text">{isActive ? 'hide' : 'view'} wishlist</div>
      </button>
    </Styling>
  )
  
}
