import React, {useState, useEffect} from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import {cssVariables} from '../styles/variables'
import {mixins} from '../styles/mixins'
import Header from '../components/global/Header'
import Footer from '../components/global/Footer'
import {ParallaxProvider } from 'react-scroll-parallax'
import Lightbox from '../components/global/Lightbox'
import Infobox from '../components/global/InfoBox'
import { ContextProviderComponent } from "./Context"
import WishlistCount from "../components/reuseable/WishlistCount"
import WishlistNotification from "../components/reuseable/WishlistNotification"
import handleInactiveProducts from "../utils/handleInactiveProducts"
import handleShowForm from '../utils/handleShowForm';
import handleHideForm from "../utils/handleHideForm"
import toggleOffPageHide from '../utils/toggleOffPageHide'
import ExDisplayOffscreen from '../components/exDisplay/exDisplayOffscreen'
// import handleAddToWishlist from '../utils/handleAddToWishlist'
import gsap from 'gsap'

import { useStaticQuery, graphql } from "gatsby"


const GlobalStyle = createGlobalStyle`

  :root{
    ${cssVariables}
  }

  *{
  box-sizing: border-box;
  }

  html{
      font-size: 62.5% !important;
      background:var(--blue_dark);
      /* cursor: none; */
  }
  body{
    font-size: 1.6rem;
    ${({theme}) => theme.minerva()}  
    background:var(--blue_dark)
  }

  p:last-child{
    margin-bottom: 0 !important;
  }

  #main{
  width: 100vw;
  position: relative;
  }

  .libre,
  .baskerville{
    ${({theme}) => theme.baskerville()}  
  }
  
  .minerva{
    ${({theme}) => theme.minerva()}  
  }

  .button{
      ${({theme}) => theme.button()}  
      ${({theme}) => theme.easing('0.5')}  

      &:hover{
          ${({theme}) => theme.buttonHover()}  
      }
  }
  .button--light{
      ${({theme}) => theme.button('--blue_light')}  
      ${({theme}) => theme.easing('0.5')}  

      &:hover{
          ${({theme}) => theme.buttonHover('--blue_dark', '--blue_light')}  
      }
  }

  .x_large{
    /* ${({theme}) => theme.fontSize('85')}   */
    font-size:clamp(3rem,4vw,5rem);
    line-height:1.5em;
    /* line-height:1.25em; */
  }
  .large{
    /* ${({theme}) => theme.fontSize('45')}   */
    font-size: clamp(2.5rem, 3vw, 4rem);
    line-height:1.5em;
  }
  .medium{
    /* ${({theme}) => theme.fontSize('25')}   */
    font-size: clamp(2rem, 2.5vw, 3rem);
    line-height:1.5em;
  }

  .title_xl{
    /* ${({theme}) => theme.fontSize('40')}  
    ${({theme}) => theme.fontSizeLineHeight('40','1.5')}   */
    font-size: clamp(3rem, 7vw, 4rem);
    line-height:1.5em;
  }
  .title_l{
    font-size: clamp(2rem, 2.5vw, 3rem);
    line-height:1.5em;
    /* ${({theme}) => theme.fontSize('30')}  
    ${({theme}) => theme.fontSizeLineHeight('30','1.5')}   */
  }
  .title_m{
    font-size: clamp(1.8rem, 2vw, 2.5rem);
    line-height:1.5em;    
    /* ${({theme}) => theme.fontSize('25')}  
    ${({theme}) => theme.fontSizeLineHeight('25','1.5')}   */
  }

  

  .offpage-translate{
  z-index: 100;
  position: relative;
  }

  .offpage-translate-bg{
  z-index: 1;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background:var(--blue_light);
  }
`

export default function Layout(props) {
  
  const [wishlistDrawerActive, setWishlistDrawerActive] = useState();
  const [wishlist, setWishlist] = useState([]);


  useEffect(() => {
    if(localStorage.getItem('wishlist') !== null){
      setWishlist(JSON.parse(localStorage.getItem('wishlist')))
    }
  }, [])

  useEffect(() => {
    const wishlistCount = document.querySelector('.wishlist-count')

    if(typeof window !== 'undefined'){
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
    }
    handleInactiveProducts()
    if(wishlist !== null && wishlist.length === 0){
      toggleOffPageHide('.exdisplay-product')
      handleHideForm()
      setWishlistDrawerActive(false)
    }else{
      wishlistCount.classList.add('active')
    }
  }, [wishlist])


  const handleAddToWishlist = (e) => {

    const target = e.target    
    const product = target.getAttribute('data-title')
    const price = target.getAttribute('data-price')

    if(wishlist.some(wishlist => wishlist.name === product)){
      console.log("Object found inside the array.");
    } 
    
    else{
      setWishlist(
        [
          {
            name: product, 
            price: price,
          },
          ...wishlist
        ]
      )
      gsap.to('.wishlist-notification', {y:0, opacity:1, duration:1, ease:"expo.out", onComplete: function(){
          gsap.to('.wishlist-notification', {y:30, opacity:0, duration:1, ease:"expo.out", delay:1})
      }})
    }
  }
 
  
  const handleRemoveItemFromWishlist = (e) => {
    const target = e.target
    const targetTitle = target.closest('.remove').getAttribute('data-product-title')

    const updatedWishlist = wishlist.filter(product => product.name !== targetTitle);
    gsap.to('#main', {pointerEvents:'all'})
    setWishlist(updatedWishlist)
  }


  const childrenWithProps = React.Children.map(props.children, (child, i) =>
    React.cloneElement(child, { 
      handleAddToWishlist: (e) => handleAddToWishlist(e), 
      wishlistCount: wishlist.length
    })
  );

  const allExDisplayProducts = useStaticQuery(graphql`
    query {
      allExdisplay: allWpExDisplay(sort: {fields: date, order: DESC}) {
        nodes {
          title
          customHeroImage {
            heroImage{
                localFile{
                    childImageSharp {
                      gatsbyImageData(
                        width: 1500
                        placeholder: NONE
                        formats: [AUTO, WEBP]
                      )
                    }
                }
            }
          }
          exDisplayProductFields {
            price
            originalPrice
          }
        }
      }
      settings:  wp {
        siteSettings {
          acfOptions {
            enquireNowText
          }
        }
      }
    }
  `)
 

console.log(allExDisplayProducts)

  return (
    <ContextProviderComponent>
      <ParallaxProvider>
        <ThemeProvider theme={mixins}>
          <GlobalStyle />
          <Header className={`offpage-translate`} path={props.location.pathname}/>
          <main id="main" className={''}>{childrenWithProps}</main>
          <Footer className={`offpage-translate`} />
          <Lightbox />
          <Infobox />
          <WishlistCount 
            wishlist={wishlist}
            wishlistDrawerActive={wishlistDrawerActive}
            setWishlistDrawerActive={setWishlistDrawerActive}
          />
          <WishlistNotification 
            wishlist={wishlist}
            wishlistDrawerActive={wishlistDrawerActive}
          />
          <ExDisplayOffscreen 
          settings={allExDisplayProducts.settings} 
          wishlist={wishlist !== null && wishlist.length !== 0 ? wishlist : []} 
          exDisplayProducts={allExDisplayProducts.allExdisplay.nodes} 
          handleRemoveItemFromWishlist={handleRemoveItemFromWishlist} 
          handleShowForm={handleShowForm}
          wishlistDrawerActive={wishlistDrawerActive}
          setWishlistDrawerActive={setWishlistDrawerActive}/>
          
        </ThemeProvider>
      </ParallaxProvider>
    </ContextProviderComponent>
  )
}
