import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import ContextConsumer from "../../../layouts/Context"
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import {RiArrowDropRightLine, RiArrowDropLeftLine} from 'react-icons/ri'

const _Lightbox = styled.div`
width:100vw;
height:100vh;
position: fixed;
z-index:99999999;
background:rgba(0,0,0,0.8);
top:0;
left:0;
display: flex;
justify-content: center;
align-items: center;
opacity: ${props => props.isActive.opacity};
pointer-events: ${props => props.isActive.pointerEvents};
${({theme}) => theme.easing('0.5')}  
cursor: pointer;

    .rotate-notice{
    display: none;
    @media only screen 
        and (min-device-width: 320px) 
        and (max-device-width: 480px)
        and (-webkit-min-device-pixel-ratio: 2)
        and (orientation: portrait) {
            position: absolute;
            bottom: 2rem;
            color: white;
            font-size: 1.4rem;  
            display: block;
        }

    }

    .close{
    position: absolute;
    top:2rem;
    right:2rem;
    color:white;
    pointer-events: none;
    }

    .lightbox-inner{
    /* min-width: 90%;
    min-height:80%; */
    height:0;
    background:white;
    padding:1rem;
    position: relative;
    transform:${props => props.isActive.innerScale};
    ${({theme}) => theme.easing('0.25')}  
    cursor: initial;
    width: 90%;
    max-width: 1420px;
    padding-bottom: clamp(10vw, 56%, 80vh);

        .the-image{
        width:calc(100% - 2rem);
        height:calc(100% - 2rem);
        position: absolute;
        object-fit: contain;

            .gatsby-image-wrapper{
                height: 100%;
                width: 100%;

                img{
                    object-fit:contain !important;
                }

            }
            

        }

        .arrow{
        position: absolute;
        top:50%;
        z-index: 99999999999999;
        cursor: pointer;
        transform:translateY(-50%);

            svg{
            font-size:5rem;
            background:var(--white);
            color:var(--blue_dark);
            ${({theme}) => theme.easing('0.5')}  
            }
            
            &:hover{
                svg{
                color:var(--white);
                background: var(--blue_dark);
                }
            }

            &.prev{
            left:1rem;
            }

            &.next{
            right:1rem;
            }

        }

    }


`

export default function Lightbox() {
    return (
        <ContextConsumer>
            {({ data, set }) => 
                
                <_Lightbox 
                isActive={data.menuOpen === true ? {opacity:1, pointerEvents:'all', innerScale:'scale(1)'} : {opacity:0, pointerEvents:'none', innerScale:'scale(0.5)'}} 
                className="lightbox-wrap"
                onClick={(e) => {
                        if(e.target.classList.contains('lightbox-wrap')){
                            set({ menuOpen: !data.menuOpen })
                        }
                    }
                }
                >
                    <div className="close" >CLOSE</div>
                    <div className="lightbox-inner">
                        {
                            data.imageIndex > 0  ?
                            <div 
                            className="prev arrow" 
                            onClick={() => {
                                set({ 
                                    image: data.theGallery[data.imageIndex - 1].image.localFile,
                                    imageIndex: data.imageIndex - 1
                                })
                            }}><RiArrowDropLeftLine /></div>
                            : '' 
                        }
                        <div className="the-image">
                            <GatsbyImage image={getImage(data.image)} className="image" alt={'test'} />
                        </div>
                        {
                             data.theGallery!== null && data.imageIndex !== data.theGallery.length - 1  ?
                            <div 
                            className="next arrow" 
                            onClick={() => {
                                set({
                                    image: data.theGallery[data.imageIndex + 1].image.localFile,
                                    imageIndex: data.imageIndex + 1
                                })
                            }}><RiArrowDropRightLine /></div>
                            : '' 
                        }
                    </div>
                    <div className="rotate-notice">Please rotate your phone to landscape for optimum viewing</div>
                </_Lightbox>
             }
        </ContextConsumer>
    )
}