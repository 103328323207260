import React from 'react'
import { Styling } from './styled.js'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {AiFillStar} from 'react-icons/ai'
import {AiOutlineStar} from 'react-icons/ai'

export default function ReviewsSlider({reviews}) {
    const settings = {
        infinite: true,
        fade:true,
        arrows:false,
        dots:false,
        speed: 500,
        autoplay:true,
        slidesToShow: 1,
        pauseOnHover:false,
        slidesToScroll: 1,
        adaptiveHeight: true,
      };
      const getStarRating = (rating) => {
          let stars = []
          for(let i = 1; i <= 5; i++){
            if(rating >= i){
                stars.push(<AiFillStar key={`star_${i}`} />)
            }else{
                stars.push(<AiOutlineStar key={`star_${i}`} />)
            }
          }

          return stars;
      }
    return (
        <Styling className="reviews_slider">
            <div className={'slider_wrapper'}>
                <Slider {...settings} className="slider">
                    {reviews.map((review, index) =>[
                        <div key={`review_${index}`}>
                            <div className="text">"{review.text}"</div>
                            <div className="name">{review.author_name}</div>
                            <div className="star">{
                                getStarRating(review.rating)
                            }</div>
                        </div>
                    ])}
                </Slider>
            </div>      
        </Styling>
    )
}
