import React, {useState, useRef} from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import {CgCloseO} from 'react-icons/cg'
import {Link, navigate} from 'gatsby'
import gsap from 'gsap';
import toggleOffPageShow from '../../../utils/toggleOffPageShow'
import toggleOffPageHide from '../../../utils/toggleOffPageHide'
import handleHideForm from '../../../utils/handleHideForm';

export default function ExDisplayOffscreen({settings, wishlist, exDisplayProducts, handleRemoveItemFromWishlist, handleShowForm, wishlistDrawerActive, setWishlistDrawerActive}) {
    
    let theList = [];
    let formProducts = '';
    
    Array.from(exDisplayProducts).filter(product => {
        wishlist.forEach(item => {
            if(product.title === item.name){
              return (
                theList.push(product)
              )
            }
        })
    }) 

    const handleShowWishlistDrawer = () => {
        console.log('clicked', wishlistDrawerActive)
          if(wishlistDrawerActive === false){
            toggleOffPageShow({page:'other'})
            gsap.to('#main', {pointerEvents:'none'})
            setWishlistDrawerActive(true)
          }else{
            gsap.to('#main', {pointerEvents:'all'})
            toggleOffPageHide('.exdisplay-product')
            handleHideForm()
            setWishlistDrawerActive(false)
          }
      } 
  

    let newTotal = 0;
    wishlist.forEach((item, index) => {
        const currentPrice = item.price.substring(1);
        newTotal = newTotal + parseInt(currentPrice);  
        if(wishlist.length > 1){
            formProducts = formProducts +item.name+ ', ';  
        }else{
            formProducts = formProducts +item.name;  
        }
    })

    const formRef = useRef()
    const [formData, setFormData] = useState();
    const [formName, setFormName] = useState('');
    const [formPhone, setFormPhone] = useState('');
    const [formEmail, setFormEmail] = useState('');
    const [formPreferred, setFormPreferred] = useState('');

    const encode = data => {
        return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&")
    }

    const handleInput = (e) => {

        if(e.target.name === 'name'){
            setFormName(e.target.value)
        }
        if(e.target.name === 'email'){
            setFormEmail(e.target.value)
        }
        if(e.target.name === 'phone'){
            setFormPhone(e.target.value)
        }
        if(e.target.name === 'preferred'){
            setFormPreferred(e.target.value)
        }
        console.log({name:formName, email: formEmail, phone: formPhone, preferred: formPreferred, products:formProducts})
        setFormData({name:formName, email: formEmail, phone: formPhone, preferred: formPreferred, products:formProducts})
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        const form = formRef.current
        fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
            "form-name": form.getAttribute("name"),
            ...formData,
        }),
        })
        .then(() => navigate("/"))
        .catch(error => alert(error))

        setFormData()
    }
 
    return (
        <Styling className="exdisplayOffscreenWrapper">
            <div className="wishlist-overview">
                <ul className="wishlist-list">
                    {
                        theList.map((product, index) => {
                            const theImage = getImage(product.customHeroImage.heroImage.localFile);
                
                            return (
                                <li className="wishlist-list-item" key={'wishlist_item_'+index}>
                                    <div className="info">
                                        <div className="image-outer">
                                            <GatsbyImage image={theImage} className="image" alt={'test'} />
                                        </div>
                                        <div className="info-inner">
                                            <div className="name title small">{product.title}</div>
                                            <div className="price">{product.exDisplayProductFields.price}</div>
                                        </div>
                                    </div>
                                    <div className="remove" title="Remove from wishlist" data-product-title={product.title} onClick={(e) => handleRemoveItemFromWishlist(e)}>
                                        <CgCloseO />
                                        <div className="hidden">
                                            <div className="hidden-inner">Remove from wishlist</div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })
                    }
                </ul>
                <div className="wishlist-footer">
                    {theList.length >= 1 ? <div className="total medium">Total: £{newTotal}</div> : "" }
                    {theList.length >= 1 ? <button className="showForm button" onClick={() => handleShowForm()}>Make enquiry</button> : "" }
                </div>
            </div>
            <div className="form-wrapper">
                {console.log(settings)}
                <div className="text">{settings.siteSettings.acfOptions.enquireNowText}</div>
                <form id="ex-display" method="POST" data-netlify="true" name="ex-display" ref={formRef}>
                    <input type="text" onChange={(e) => handleInput(e)} onBlur={(e) => handleInput(e)} name="name" placeholder="Name"/>
                    <input type="text" onChange={(e) => handleInput(e)} onBlur={(e) => handleInput(e)} name="phone" placeholder="Phone" />
                    <input type="email" onChange={(e) => handleInput(e)} onBlur={(e) => handleInput(e)} name="email" placeholder="Email" />
                    <div className="select_wrap">
                        <select name="preferred" id="preferredContact" onChange={(e) => handleInput(e)} onBlur={(e) => handleInput(e)} >
                            <option selected disabled>Please choose a preferred contact method</option>
                            <option value="Email">Email</option>
                            <option value="Phone">Phone</option>
                        </select>
                    </div>
                    <textarea name="products" onChange={(e) => handleInput(e)} onBlur={(e) => handleInput(e)} value={ formProducts}/>
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="button">Send</button>
                </form>
            </div>
            <div className="continueShopping"><button className='button'  onClick={handleShowWishlistDrawer}>&larr; Continue shopping</button></div>
        </Styling>
    )
}
