import styled from 'styled-components'

const Styling = styled.div`
    .wishlist-count{
    position: fixed;
    right: 0;
    z-index: 100000;
    top: 50%;
    padding: 1rem;
    background: var(--white);
    text-align: center;
    border:0;
    --webkit-appearance:none;
    ${({theme}) => theme.easing('0.5')}  
    cursor: pointer;
    transform: translateX(100%) translateY(-50%);
    
        &.active{
        transform: translateX(0%) translateY(-50%);
        }


        &:hover{
        background:var(--blue_dark);
        color:var(--white);
        }

        .text{
        writing-mode: vertical-rl;
        text-orientation: mixed;
        ${({theme}) => theme.fontSize('12')}  
        margin-top: 0.5rem;
        margin-left: 2px;
        }

    }
`
export { Styling }