

const variables = {
    white: '#ffffff',
    black: '#000000',
    blue_dark: '#1C2442',
    blue_light: '#DCDDE2',
    grey: '#747474',
}

const cssVariables = () => {
    let cssVariables = '';

    Object.entries(variables).forEach((variable) => {
      const [key, value] = variable;
      // console.log(key, value);
      cssVariables += `--${key}: ${value}; `
    })

    return cssVariables;

}



export {
    cssVariables
}