import gsap from "gsap";


const handleInactiveProducts = (wishlist) => {
    gsap.to(`.exdisplay-product .image-wrapper`, {pointerEvents:'all', opacity:1});      
    gsap.to(`.exdisplay-product .product-info`, {pointerEvents:'all', opacity:1});      
    // exDisplayProducts.forEach(item => {    
    //   gsap.to(`.exdisplay-product .image-wrapper`, {pointerEvents:'all', opacity:1});      
    //   gsap.to(`.exdisplay-product .product-info`, {pointerEvents:'all', opacity:1});      
    // })
    if(wishlist){
      wishlist.forEach(item => {    
        gsap.to(`.exdisplay-product[data-title="${item.name}"] .image-wrapper`, {pointerEvents:'none', opacity:0.3});      
        gsap.to(`.exdisplay-product[data-title="${item.name}"] .product-info`, {pointerEvents:'none', opacity:0.3});      
      })      
    }
  }

  export default handleInactiveProducts
