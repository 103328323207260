import React from 'react'
import Logo from "../../../images/logomark.inline.svg"
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { Styling } from './styled.js'
import { useStaticQuery, graphql } from "gatsby"
import ReviewsSlider from './ReviewsSlider'
import {FaFacebook, FaPinterest, FaInstagram} from 'react-icons/fa'


export default function Footer({className}) {
    const data = useStaticQuery(graphql`
    {
      allGooglePlacesReview {
        nodes {
          text
          rating
          author_name
        }
      }
      wp {
        siteSettings {
          acfOptions {
            email
            fax
            googleMapDirectionsUrl
            phone
            phoneFormatted
            address
            facebook
            instagram
            pinterest
          }
        }
      }
      footer1: wpMenu(name: {eq: "Footer 1"}) {
        menuItems {
          nodes {
            label
            path
          }
        }
      }
      footer2: wpMenu(name: {eq: "Footer 2"}) {
        menuItems {
          nodes {
            label
            path
          }
        }
      }
    }
  `)
  const {
    email, fax, googleMapDirectionsUrl, phone, phoneFormatted, address, facebook, instagram, pinterest
  } = data.wp.siteSettings.acfOptions;

  const menu1 = data.footer1.menuItems.nodes;
  const menu2 = data.footer2.menuItems.nodes;

    return (
        <Styling>
            <div className={`footer ${className}`}>
              <div className="upper">
                <Link to='/'>
                    <Logo className="logo" />
                </Link>
                <ReviewsSlider reviews={data.allGooglePlacesReview.nodes}/>
              </div>
              <div className="lower">
                  <div className="col">
                      <div className="address">
                          <a href={googleMapDirectionsUrl} target="_blank" rel="noopener noreferrer">{parse(address)}</a>
                      </div>
                      <div className="phone"><a href={`tel:${phoneFormatted}`}>{phone}</a></div>
                      <div className="fax">{fax}</div>
                      <div className="email"><a href={`mailto:${email}`}>{email}</a></div>
                      <ul className="social">
                        <li><a href={facebook} target="_blank"><FaFacebook /></a></li>
                        <li><a href={instagram} target="_blank"><FaInstagram /></a></li>
                        <li><a href={pinterest} target="_blank"><FaPinterest /></a></li>
                      </ul>
                  </div>
                 
                  <div className="col">
                      <ul>
                          {
                              menu1.map((link, index) => <li key={'menu1_'+index}><Link to={link.path}>{link.label}</Link></li>)
                          }
                      </ul>
                  </div>
                  <div className="col">
                      <ul>
                          {
                              menu2.map((link, index) => <li key={'menu2_'+index}><Link to={link.path}>{link.label}</Link></li>)
                          }
                      </ul>
                  </div>
              </div>
            </div>
        </Styling>
    )
}
