import styled from 'styled-components'

const Styling = styled.div`
   .wishlist-notification{
    position: fixed;
    bottom:4rem;
    right:4rem;
    color:var(--blue_dark);
    padding:2rem;
    background:var(--white);
    border:1px solid var(--blue_dark);
    opacity:0;
    transform:translateY(3rem);
    pointer-events: none;
    z-index: 200;
    }
`
export { Styling }